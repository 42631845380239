<template lang="pug">
.frontend-rules-color-box(:style="{ 'background-color': getBackgroundColor() }" :class="type")
  img(:src="require(`@/assets/admin/svg/conditions/${type}.svg`)")
</template>

<script>
  import { isShopifyTimeAndContextRule, isShopifyVisitorsRule } from '@/config/frontendRules';

  export default {
    props: {
      type: { type: String, required: true },
    },

    methods: {
      getBackgroundColor() {
        if (this.type === 'visitorAttribute') {
          return '#d87b7b';
        }

        if (isShopifyTimeAndContextRule(this.type)) {
          return '#e9a249';
        }
        if (isShopifyVisitorsRule(this.type)) {
          return '#d87b7b';
        }
        return '#7dc799';
      },

      getBackgroundImage() {
        const imgPath = require(`@/assets/admin/svg/conditions/${this.type}.svg`);

        return `url('${imgPath}')`;
      },
    },
  };
</script>
